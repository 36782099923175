import * as React from "react";
import { Link } from "gatsby";

import HeaderLogo from "../svg/header_logo.svg";
import TeleportLogo from "../svg/teleport_logo.svg";
import { IMG_BASE } from "../config";

const Header = ({ location, inst, fb }) => {
    const [ expandNav, toggleNav ] = React.useState(false);
    const [ scrolled, setScrolled ] = React.useState(false);

    const toggle = () => toggleNav(!expandNav);
    const closeNav = () => toggleNav(false);

    React.useEffect(() => {
        document.body.classList[expandNav ? "add" : "remove"]("is-nav-open");
    }, [expandNav]);

    React.useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 100;

            if (isScrolled !== scrolled) {
                setScrolled(!scrolled);
            }
        };

        document.addEventListener("DOMContentLoaded", () => {
            if (window.scrollY > 100) {
                setScrolled(true);
            }
        });

        document.addEventListener("scroll", handleScroll, { passive: true });
        document.body.classList[scrolled ? "add" : "remove"]("is-scrolled");

        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, [scrolled]);

    return (
        <header className="site-header">
            <div className="site-header__inner">
                {/*<div className="site-header__item _logo hide-mobile">*/}
                {/*    {*/}
                {/*        <Link to="/">*/}
                {/*            <TeleportLogo className="site-header__teleport-logo" />*/}
                {/*        </Link>*/}
                {/*    }*/}
                {/*</div>*/}

                <div className="site-header__item site-header__item_menu">
                    <div className="show-tablet">
                        <button type="button" className="burger js-burger" onClick={ toggle }>
                            <span className="burger__inner">
                                <span className="burger__line"></span>
                                <span className="burger__line"></span>
                                <span className="burger__line"></span>
                            </span>
                        </button>
                    </div>

                    <div className="site-nav">
                        <div className="show-mobile">
                            <Link to="/" className="site-nav__link" onClick={ closeNav }>
                                <TeleportLogo className="site-header__teleport-logo" />
                            </Link>
                        </div>

                        <ul className="site-nav__inner">
                            <li className="site-nav__item">
                                {location.pathname !== '/about/' ?
                                    <Link to='/about/' className="site-nav__link" activeClassName="is-active"
                                          onClick={closeNav}>About</Link> :
                                    <span className="site-nav__link is-active">About</span>
                                }
                            </li>
                            <li className="site-nav__item">
                                {location.pathname !== '/works/' ?
                                    <Link to='/works/' className="site-nav__link" activeClassName="is-active"
                                          onClick={closeNav}>Works</Link> :
                                    <span className="site-nav__link is-active">Works</span>
                                }
                            </li>
                            <li className="site-nav__item">
                                {location.pathname !== '/services/' ?
                                    <Link to='/services/' className="site-nav__link" activeClassName="is-active"
                                          onClick={closeNav}>Services</Link> :
                                    <span className="site-nav__link is-active">Services</span>
                                }
                            </li>
                            <li className="site-nav__item">
                                {location.pathname !== '/contact/' ?
                                    <Link to='/contact/' className="site-nav__link" activeClassName="is-active"
                                          onClick={closeNav}>Contact</Link> :
                                    <span className="site-nav__link is-active">Contact</span>
                                }
                            </li>
                        </ul>

                        <div className="show-mobile">
                            <ul className="socials-list socials-list_horizontal">
                                {inst &&
                                    <li className="socials-list__item">
                                        <a href={inst.url} className="socials-list__link" target="_blank"
                                           rel="noreferrer">
                                        <img src={`${IMG_BASE}/${inst.icon}`} alt="" className="socials-list__icon"/>
                                        </a>
                                    </li>
                                }
                                {fb &&
                                    <li className="socials-list__item">
                                        <a href={fb.url} className="socials-list__link" target="_blank" rel="noreferrer">
                                            <img src={`${IMG_BASE}/${fb.icon}`} alt="" className="socials-list__icon"/>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>

                {/*<div className="site-header__item _logo hide-mobile">*/}
                {/*    {*/}
                {/*        <Link to="/">*/}
                {/*            <HeaderLogo  className="site-header__header-logo" />*/}
                {/*        </Link>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </header>
    )
}

export default Header;
