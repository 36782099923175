import * as React from "react";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";

const Screen = () => {
    return (
        <div className="site-wrapper__screen">
            <TopLeftCorner className="site-wrapper__corner site-wrapper__corner_top-left" />
            <TopRightCorner className="site-wrapper__corner site-wrapper__corner_top-right" />
            <BottomRightCorner className="site-wrapper__corner site-wrapper__corner_bottom-right" />
            <BottomLeftCorner className="site-wrapper__corner site-wrapper__corner_bottom-left" />
        </div>
    )
}

export default Screen;
