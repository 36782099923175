import React from "react"
import { TransitionGroup, Transition as ReactTransition } from "react-transition-group";

const timeout = 500;
const getTransitionStyles = {
    entering: {
        opacity: 0,
    },
    entered: {
        transition: `opacity ${ timeout }ms ease-in`,
        opacity: 1,
    },
    exiting: {
        // position: "absolute",
        transition: `opacity ${ timeout }ms ease-in-out`,
        opacity: 0,
    },
};

class Transition extends React.PureComponent {
    render() {
        const { children, location } = this.props;

        return (
            <TransitionGroup>
                <ReactTransition
                    key={ location.pathname }
                    appear={ true }
                    timeout={ {
                        enter: timeout,
                        exit: timeout,
                    } }
                >
                    { status => (
                        <div style={{
                            opacity: 0,
                            ...getTransitionStyles[status],
                            }}
                        >
                            { children }
                        </div>
                    ) }
                </ReactTransition>
            </TransitionGroup>
        )
    }
}

export default Transition;