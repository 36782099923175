import * as React from "react";
import { IMG_BASE } from "../config";

const Socials = ({ extraClass, inst, fb }) => {
    return (
        <ul className={ `socials-list site-wrapper__socials-list${ extraClass ? ' ' + extraClass : '' }` }>
            {inst &&
                <li className="socials-list__item">
                    <a href={inst.url} className="socials-list__link" target="_blank" rel="noreferrer">
                        <img src={`${IMG_BASE}/${inst.icon}`} alt="" className="socials-list__icon"/>
                    </a>
                </li>
            }
            {fb &&  
                <li className="socials-list__item">
                    <a href={fb.url} className="socials-list__link" target="_blank" rel="noreferrer">
                        <img src={`${IMG_BASE}/${fb.icon}`} alt="" className="socials-list__icon"/>
                    </a>
                </li>
            }
        </ul>
    )
}

export default Socials;