import React, { useEffect, useState,  } from "react";
import classNames from "classnames";
import Transition from "./transition";
import { isMobile, isIOS } from "react-device-detect";

import Header from "./header";
import Screen from "./screen";
import Socials from "./socials";

import RotateImg from "../svg/mobile_rotate.svg";

const setDocumentHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${ vh }px`);
}

const Layout = (props) => {
    const { children, location, pageContext } = props;
    const preparedSocialList = pageContext.contacts.items.reduce((acc, item) => ({
        ...acc,
        [item.name.toLowerCase()]: item
    }), {});
    const {facebook, instagram} = preparedSocialList
    const [ isLandscape, setLandscape ] = useState(false);

    useEffect(() => {
        document.documentElement.style.setProperty("--vh", `${ window.innerHeight * 0.01 }px`);

        window.addEventListener("resize", setDocumentHeight);
        document.querySelector('.site-wrapper').scrollTo({top:0})
        if (isIOS) {
            document.body.classList.add("_ios");
        }

        return () => {
            window.removeEventListener("resize", setDocumentHeight);
        };
    }, [location.pathname]);

    useEffect(() => {
        window.addEventListener("orientationchange", (e) => {
            if (Math.abs(window.orientation) === 90) {
                setLandscape(true);
            } else {
                setLandscape(false);
            }
        });
    }, []);

    return (
            <main className={
                classNames({
                    "site-wrapper": true,
                    // "modal-open": data.menuOpen,
                    [`_${location.pathname.slice(1, -1) || "home"}`]: true,
                })
            }>
                {/* Заглушка */}
                {
                    isMobile && isLandscape && process.env.NODE_ENV !== "development" && (
                        <div className="orientation-notice">
                            <div className="orientation-notice__inner">
                                <RotateImg alt="" className="orientation-notice__img" />

                                <p className="orientation-notice__text">Please rotate your mobile</p>
                            </div>
                        </div>
                    )
                }
                {/* /Заглушка */}

                <Screen />
                <Header location={ location } fb={facebook} inst={instagram} />            
                <Socials extraClass="socials-list_aside" fb={facebook} inst={instagram}/>

                <Transition location={ location } style={{ opacity: 0 }}>
                    <div className="page-content">
                        { children }
                    </div>
                </Transition>
            </main>

    )
}

export default Layout;